<template>
    <div class="pageBox">
        <el-table 
        v-loading="loading"
        size="large" 
        :data="tableData"
        :highlight-current-row="true" 
        style="width: 100%">
            <el-table-column prop="name" label="名称" align="center" />
            <el-table-column prop="relicname" label="文物名称" align="center" />
            <el-table-column prop="position" label="博物馆名称" align="center" />
            <el-table-column prop="material" label="材质" align="center" />
            <el-table-column prop="result" label="得分" align="center" />
            <el-table-column label="操作" width="240" align="center">
                <template #default="scope">
                    <el-button type="success" size="small"  plain @click="oper(scope.row.isshow,scope.row)">{{scope.row.isshow?'撤销发布':'去发布'}}</el-button>
                    <el-button type="danger" size="small" @click="del(scope.row.id)" plain>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination 
            background 
            :page-size="query.size"
            :current-page="query.page"
            layout="total, prev, pager, next"
            @current-change="pageChange" 
            :total="total" />
        <detilesPage ref="detilesPage" @OK="CX"/>
    </div>
</template>
<script>
import { case_List, case_del } from '@/api/caseView.js'
import detilesPage from './modules/detiles.vue'
export default{
    components:{
        detilesPage
    },
    data(){
        return{
            loading:false,
            query:{
                page:1,
                size:10,
            },
            total:0,
            tableData:[]
        }
    },
    mounted(){
        this.CX(0)
    },
    methods:{
        pageChange(page){
            this.query.page = page
            this.CX(1)
        },        
        oper(type,data){
            this.$refs.detilesPage.open(type,data)
        },
        CX(type){
            if(type==0){
                this.query.page = 1
            }
            case_List(this.query).then(r=>{
                this.tableData = r.data.data
                this.total = r.data.pageInfo.total
            })
        },
        del(id){
            this.$confirm(
                '此操作将永久删除该信息，是否继续?',
                '删除',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() => {
                case_del(id).then(r=>{
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.CX(0)
                })
            })
            .catch(() => {
                
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
}
.el-pagination{
    margin-top: 20px;
    justify-content: center;
}
/deep/.el-pagination.is-background .el-pager li.is-active{
    background-color: #803a15;
}
</style>